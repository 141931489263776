import React, { SVGAttributes } from 'react'

export const HoneyStrokedFlatten: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20 2.3094C22.4752 0.880339 25.5248 0.880339 28 2.3094L40.7846 9.6906C43.2598 11.1197 44.7846 13.7607 44.7846 16.6188V31.3812C44.7846 34.2393 43.2598 36.8803 40.7846 38.3094L28 45.6906C25.5248 47.1197 22.4752 47.1197 20 45.6906L7.21539 38.3094C4.74018 36.8803 3.21539 34.2393 3.21539 31.3812V16.6188C3.21539 13.7607 4.74018 11.1197 7.21539 9.6906L20 2.3094Z"
      fill="white"
    />
    <path
      d="M20 2.3094C22.4752 0.880339 25.5248 0.880339 28 2.3094L40.7846 9.6906C43.2598 11.1197 44.7846 13.7607 44.7846 16.6188V31.3812C44.7846 34.2393 43.2598 36.8803 40.7846 38.3094L28 45.6906C25.5248 47.1197 22.4752 47.1197 20 45.6906L7.21539 38.3094C4.74018 36.8803 3.21539 34.2393 3.21539 31.3812V16.6188C3.21539 13.7607 4.74018 11.1197 7.21539 9.6906L20 2.3094Z"
      fill="#FECC00"
      fillOpacity="0.28"
    />
    <path
      d="M21.25 4.47446C22.9517 3.49198 25.0483 3.49198 26.75 4.47446L39.5346 11.8557C41.2363 12.8381 42.2846 14.6538 42.2846 16.6188V31.3812C42.2846 33.3462 41.2363 35.1619 39.5346 36.1443L26.75 43.5255C25.0483 44.508 22.9517 44.508 21.25 43.5255L8.46539 36.1443C6.76368 35.1619 5.71539 33.3462 5.71539 31.3812V16.6188C5.71539 14.6538 6.76368 12.8381 8.46539 11.8557L21.25 4.47446Z"
      stroke="#FECC00"
      strokeOpacity="0.22"
      strokeWidth="5"
    />
    <path
      d="M15.2173 15.1076C14.1995 14.4315 14.0382 12.8952 14.9826 12.1199C15.5309 11.6698 16.3004 11.6206 16.8448 12.0755C18.5172 13.4729 21.9217 17.0231 23.0179 23.4468C24.4916 32.083 22.9946 28.3096 21.5456 22.7506C20.63 19.238 17.441 16.585 15.2173 15.1076Z"
      fill="#429E39"
    />
    <path
      d="M32.0003 27.6675C32.0003 32.6644 28.1627 36.239 23.4289 36.239C18.695 36.239 14.8574 32.6644 14.8574 27.6675C14.8574 23.7281 17.2384 22.4294 17.2384 19.5723C23.1531 19.5723 23.6669 19.5722 29.6193 19.5723C29.6193 22.9056 32.0003 23.7281 32.0003 27.6675Z"
      fill="url(#paint0_linear_1_2087)"
    />
    <rect x="15.8096" y="17.6675" width="15.2381" height="2.85714" rx="1.42857" fill="#90AA3E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.1915 18.7153C28.1915 18.7023 28.1913 18.6893 28.1911 18.6764V18.1439C28.1911 17.355 27.5515 16.7154 26.7625 16.7154C26.6733 16.7154 26.586 16.7236 26.5013 16.7392C26.4004 16.7235 26.2969 16.7153 26.1915 16.7153H23.5248C23.4192 16.7153 23.3156 16.7235 23.2145 16.7393C23.1297 16.7236 23.0423 16.7154 22.953 16.7154C22.164 16.7154 21.5244 17.355 21.5244 18.1439V23.8582C21.5244 24.6472 22.164 25.2868 22.953 25.2868C23.7149 25.2868 24.3376 24.6903 24.3793 23.9388V22.9058C24.3793 22.6428 24.5925 22.4296 24.8555 22.4296C25.1185 22.4296 25.3317 22.6428 25.3317 22.9058V23.382H25.3339V26.7154C25.3339 27.5044 25.9735 28.144 26.7625 28.144C27.5515 28.144 28.1911 27.5044 28.1911 26.7154V21.421C28.1913 21.408 28.1915 21.395 28.1915 21.382V18.7153Z"
      fill="#FECC00"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_2087"
        x1="23.4289"
        y1="19.5723"
        x2="23.4289"
        y2="36.239"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C0B240" />
        <stop offset="1" stopColor="#738F35" />
      </linearGradient>
    </defs>
  </svg>
)
