import React, { SVGAttributes } from 'react'

export const Claimed: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_i_1_3572)">
      <path
        d="M21.5 1.44338C23.047 0.550211 24.953 0.550212 26.5 1.44338L42.2846 10.5566C43.8316 11.4498 44.7846 13.1004 44.7846 14.8868V33.1133C44.7846 34.8996 43.8316 36.5502 42.2846 37.4434L26.5 46.5566C24.953 47.4498 23.047 47.4498 21.5 46.5566L5.71539 37.4434C4.16839 36.5502 3.21539 34.8996 3.21539 33.1132V14.8868C3.21539 13.1004 4.16839 11.4498 5.71539 10.5566L21.5 1.44338Z"
        fill="#F7FCEA"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 33C28.9706 33 33 28.9706 33 24C33 19.0294 28.9706 15 24 15C19.0294 15 15 19.0294 15 24C15 28.9706 19.0294 33 24 33ZM23.7682 27.6402L28.7682 21.6402L27.2318 20.3598L22.9328 25.5186L20.7071 23.2929L19.2929 24.7071L22.2929 27.7071L23.0672 28.4814L23.7682 27.6402Z"
      fill="#7F32FD"
    />
    <defs>
      <filter
        id="filter0_i_1_3572"
        x="3.21582"
        y="-1.22656"
        width="41.5684"
        height="48.4531"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.829341 0 0 0 0 0.837953 0 0 0 0 0.80695 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_3572" />
      </filter>
    </defs>
  </svg>
)
