import React, { SVGAttributes } from 'react'

export const DailyLogo: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M50 5.7735C56.188 2.20085 63.812 2.20085 70 5.7735L101.962 24.2265C108.15 27.7992 111.962 34.4017 111.962 41.547V78.453C111.962 85.5983 108.15 92.2008 101.962 95.7735L70 114.226C63.812 117.799 56.188 117.799 50 114.227L18.0385 95.7735C11.8505 92.2008 8.03848 85.5983 8.03848 78.453V41.547C8.03848 34.4017 11.8505 27.7992 18.0385 24.2265L50 5.7735Z"
      fill="white"
    />
    <path
      d="M50 5.7735C56.188 2.20085 63.812 2.20085 70 5.7735L101.962 24.2265C108.15 27.7992 111.962 34.4017 111.962 41.547V78.453C111.962 85.5983 108.15 92.2008 101.962 95.7735L70 114.226C63.812 117.799 56.188 117.799 50 114.227L18.0385 95.7735C11.8505 92.2008 8.03848 85.5983 8.03848 78.453V41.547C8.03848 34.4017 11.8505 27.7992 18.0385 24.2265L50 5.7735Z"
      fill="url(#paint0_linear_1_2132)"
    />
    <g opacity="0.2" filter="url(#filter0_i_1_2132)">
      <path
        d="M53 10.9697C57.3316 8.4688 62.6684 8.4688 67 10.9697L98.9615 29.4226C103.293 31.9235 105.962 36.5453 105.962 41.547V78.453C105.962 83.4547 103.293 88.0765 98.9615 90.5773L67 109.03C62.6684 111.531 57.3316 111.531 53 109.03L21.0385 90.5774C16.7069 88.0765 14.0385 83.4547 14.0385 78.453V41.547C14.0385 36.5453 16.7069 31.9235 21.0385 29.4226L53 10.9697Z"
        stroke="#FECC00"
        strokeWidth="12"
      />
    </g>
    <path
      d="M41.0272 37.7513C38.4878 36.0643 38.0854 32.2311 40.4418 30.2967C41.8099 29.1736 43.7298 29.0509 45.088 30.1859C49.2608 33.6725 57.7551 42.5304 60.4901 58.5579C64.1671 80.1058 60.4321 70.6907 56.8168 56.8209C54.5323 48.0568 46.5757 41.4373 41.0272 37.7513Z"
      fill="#429E39"
    />
    <path
      d="M80.9998 68.1371C80.9998 80.6045 71.4249 89.5232 59.6137 89.5232C47.8024 89.5232 38.2275 80.6045 38.2275 68.1371C38.2275 58.3081 44.1681 55.0677 44.1681 47.939C58.9257 47.939 60.2077 47.9389 75.0592 47.939C75.0592 56.2558 80.9998 58.3081 80.9998 68.1371Z"
      fill="url(#paint1_linear_1_2132)"
    />
    <rect x="40.6035" y="43.1865" width="38.0198" height="7.12871" rx="3.56436" fill="#90AA3E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.4217 39.8599C56.4532 39.8599 54.8574 41.4557 54.8574 43.4242V43.7359C54.8561 43.7771 54.8555 43.8185 54.8555 43.8601V52.4938C54.8555 52.5353 54.8561 52.5767 54.8574 52.618V57.6816C54.8574 59.6502 56.4532 61.246 58.4217 61.246C60.3903 61.246 61.9861 59.6502 61.9861 57.6816V56.4938H61.9898V55.1169C61.9898 54.5646 62.4375 54.1169 62.9898 54.1169H63.366C63.9183 54.1169 64.366 54.5646 64.366 55.1169V60.2456C64.366 60.2748 64.3648 60.3038 64.3623 60.3324V64.8104C64.3623 66.7789 65.9581 68.3747 67.9267 68.3747C69.8952 68.3747 71.491 66.7789 71.491 64.8104V43.4242C71.491 41.4557 69.8952 39.8599 67.9267 39.8599C67.8547 39.8599 67.7831 39.862 67.7122 39.8662C67.6384 39.8622 67.564 39.8601 67.4891 39.8601L58.8555 39.8601C58.7813 39.8601 58.7076 39.8621 58.6344 39.8661C58.564 39.862 58.4931 39.8599 58.4217 39.8599Z"
      fill="#FECC00"
    />
    <defs>
      <filter
        id="filter0_i_1_2132"
        x="8.03809"
        y="-0.905762"
        width="103.924"
        height="117.812"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_2132" />
      </filter>
      <linearGradient id="paint0_linear_1_2132" x1="60" y1="0" x2="60" y2="120" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FECC00" stopOpacity="0.16" />
        <stop offset="1" stopColor="#FECC00" stopOpacity="0.28" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_2132"
        x1="59.6137"
        y1="47.939"
        x2="59.6137"
        y2="89.5232"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C0B240" />
        <stop offset="1" stopColor="#738F35" />
      </linearGradient>
    </defs>
  </svg>
)
