import React, { SVGAttributes } from 'react'

export const Honey: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_41_5269)">
      <path
        d="M12 3.3094C14.4752 1.88034 17.5248 1.88034 20 3.3094L25.8564 6.6906C28.3316 8.11966 29.8564 10.7607 29.8564 13.6188V20.3812C29.8564 23.2393 28.3316 25.8803 25.8564 27.3094L20 30.6906C17.5248 32.1197 14.4752 32.1197 12 30.6906L6.14359 27.3094C3.66839 25.8803 2.14359 23.2393 2.14359 20.3812V13.6188C2.14359 10.7607 3.66839 8.11966 6.14359 6.6906L12 3.3094Z"
        fill="#FECC00"
      />
      <path
        d="M10.1448 10.4049C9.46631 9.95417 9.35879 8.92996 9.98842 8.4131C10.354 8.11301 10.8669 8.08024 11.2299 8.38349C12.3448 9.3151 14.6144 11.6819 15.3452 15.9644C16.3277 21.7219 15.3298 19.2062 14.3637 15.5002C13.7533 13.1585 11.6274 11.3898 10.1448 10.4049Z"
        fill="white"
      />
      <path
        d="M21.3329 18.7787C21.3329 22.1099 18.7745 24.493 15.6186 24.493C12.4627 24.493 9.9043 22.1099 9.9043 18.7787C9.9043 16.1524 11.4916 15.2866 11.4916 13.3819C15.4348 13.3819 15.7773 13.3818 19.7456 13.3819C19.7456 15.6041 21.3329 16.1524 21.3329 18.7787Z"
        fill="white"
      />
      <rect x="10.54" y="12.1118" width="10.1587" height="1.90476" rx="0.952381" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7944 13.4766C18.7944 13.4635 18.7943 13.4505 18.7941 13.4376V12.429C18.7941 11.903 18.3677 11.4766 17.8417 11.4766C17.6895 11.4766 17.5457 11.5123 17.4181 11.5757C17.2219 11.5114 17.0122 11.4766 16.7944 11.4766H16.35C16.132 11.4766 15.9222 11.5114 15.7258 11.5759C15.5982 11.5123 15.4543 11.4766 15.302 11.4766C14.776 11.4766 14.3496 11.903 14.3496 12.429V16.2385C14.3496 16.7645 14.776 17.1909 15.302 17.1909C15.81 17.1909 16.225 16.7932 16.2529 16.2922V15.6035C16.2529 15.4282 16.395 15.2861 16.5703 15.2861C16.7457 15.2861 16.8878 15.4282 16.8878 15.6035V15.9189C16.8883 15.9188 16.8888 15.9188 16.8893 15.9188V18.1433C16.8893 18.6692 17.3157 19.0956 17.8417 19.0956C18.3677 19.0956 18.7941 18.6692 18.7941 18.1433V13.96C18.7943 13.947 18.7944 13.934 18.7944 13.921V13.4766Z"
        fill="#FFE372"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_41_5269"
        x="-1"
        y="-1"
        width="36"
        height="36"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_41_5269" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_41_5269" result="shape" />
      </filter>
    </defs>
  </svg>
)
